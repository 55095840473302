/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRoles = /* GraphQL */ `
  mutation CreateRoles(
    $input: CreateRolesInput!
    $condition: ModelRolesConditionInput
  ) {
    createRoles(input: $input, condition: $condition) {
      id
      clientID
      Role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRoles = /* GraphQL */ `
  mutation UpdateRoles(
    $input: UpdateRolesInput!
    $condition: ModelRolesConditionInput
  ) {
    updateRoles(input: $input, condition: $condition) {
      id
      clientID
      Role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRoles = /* GraphQL */ `
  mutation DeleteRoles(
    $input: DeleteRolesInput!
    $condition: ModelRolesConditionInput
  ) {
    deleteRoles(input: $input, condition: $condition) {
      id
      clientID
      Role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNewAssessment = /* GraphQL */ `
  mutation CreateNewAssessment(
    $input: CreateNewAssessmentInput!
    $condition: ModelNewAssessmentConditionInput
  ) {
    createNewAssessment(input: $input, condition: $condition) {
      clientID
      assessordata
      governance
      leadership
      measurement
      finance
      support
      standardisation
      assetinfo
      assetlifecycle
      assetplan
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNewAssessment = /* GraphQL */ `
  mutation UpdateNewAssessment(
    $input: UpdateNewAssessmentInput!
    $condition: ModelNewAssessmentConditionInput
  ) {
    updateNewAssessment(input: $input, condition: $condition) {
      clientID
      assessordata
      governance
      leadership
      measurement
      finance
      support
      standardisation
      assetinfo
      assetlifecycle
      assetplan
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNewAssessment = /* GraphQL */ `
  mutation DeleteNewAssessment(
    $input: DeleteNewAssessmentInput!
    $condition: ModelNewAssessmentConditionInput
  ) {
    deleteNewAssessment(input: $input, condition: $condition) {
      clientID
      assessordata
      governance
      leadership
      measurement
      finance
      support
      standardisation
      assetinfo
      assetlifecycle
      assetplan
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
