import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../App';
import { API, graphqlOperation } from 'aws-amplify'
import { listNewAssessments } from '../graphql/queries'
import SurveyContext from "../context/SurveyContext";
import useAssessment from '../context/assessmentcontext'
import ReactLoading from "react-loading";

//####################################################################
export default function Home() {
  let navigate = useNavigate();
  const surveyContext = useContext(SurveyContext);
  const userContext = useContext(UserContext);
  const [assessments, setAssessments] = useState([]);
  const { theassessments, addAssessments  } = useAssessment();
  const [isloading, setIsLoading] = useState(true);


//###################################### USE EFFECTS START ###############################################
 const ListMyAssessments = `query MyQuery {
  listNewAssessments(filter: {clientID: {eq: "`+ userContext +`" }})
  {
    items {
      id
      createdAt
      clientID
      assessordata
      governance
      leadership
      measurement
      finance
      support
      standardisation
      assetinfo
      assetlifecycle
      assetplan
    }
  }
}`

//____________________________fetch clients from graphql server on first mount
async function getAssessments() {
const assessmentData = await API.graphql(graphqlOperation(ListMyAssessments))
const theassessments = assessmentData.data.listNewAssessments.items
setAssessments(theassessments)

const testdata = [1,2,3]
surveyContext.dispatch({type: 'ASSESSMENTSDDB', testdata});
setIsLoading(false)

}

useEffect(() => {
  getAssessments()
}, []);

//______________________________________________
const handlePreviousAssessments = () => {
  addAssessments(assessments)
  // console.log(assessments)
  navigate(`/viewassessments`);
}

//______________________________________________
const handleNext = () => {
  surveyContext.dispatch({ type: 'RESET_SURVEY'});
  navigate(`/0_assessor`);
}

//####################################################################
return (

  <>
        {isloading ? (
          <ReactLoading
            type={"bubbles"}
            color={"#120A0A"}
            height={100}
            width={100}
          />
        ) : (

  //######################## REACT LOADING ENDS HERE #################################

<div className="bg-white p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}
<div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">

      <div className="grid grid-cols-1 gap-2">  {/* The grid for options */}
      <div className="flex-shrink-0 flex items-center">
                  <img
                    className="hidden lg:block h-20 w-auto "
                    src="https://ewyzesvg.s3.us-west-2.amazonaws.com/sanedifullimage.png"
                  />
                </div>

         <div>
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">SANEDI ASSET MANAGEMENT TOOLBOX</span>
          <span className="block text-red-600">Ready to Dive in?</span>
        </h2>
        </div>

        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        </div>               

      </div>

      </div>
    </div>

    <div className="grid grid-cols-2 gap-2">  {/* The grid for options */}

{/* __________________________________________________________________________________ */}
  <div className="bg-white p-3 rounded text-lg text-gray-600 text-center"> 
        <button
        type="button"
        onClick={handleNext}
        className="bg-gray-600 border-gray-100 text-gray-100 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:text-gray-300 hover:bg-red-800 focus:outline-none focus:shadow-outline">
        START NEW ASSESSMENT
      </button>{' '}
    </div>

{/* __________________________________________________________________________________ */}
<div className="bg-white p-3 rounded text-lg text-gray-600 text-center"> 
        <button
        type="button"
        onClick={handlePreviousAssessments}
        className="bg-gray-600 border-gray-100 text-gray-100 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:text-gray-300 hover:bg-red-800 focus:outline-none focus:shadow-outline">
        VIEW PREVIOUS ASSESSMENTS
      </button>{' '}
    </div>

{/* __________________________________________________________________________________ */}
</div>

</div>
</div>




      )}
    </>


);
}
