import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";

// ##########################################################################
const policies = [
  { id: 'level1', name: 'Level 1', description: 'Senior management is committed to formalising an AM program' },
  { id: 'level2', name: 'Level 2', description: 'We have drafted an AM policy. Senior management and council have endorsed the AM policy.' },
  { id: 'level3', name: 'Level 3', description: 'We are starting to use our AM policy to guide our actions' },
  { id: 'level4', name: 'Level 4', description: 'We manage assets and services in accordance with our AM policy and organisational objectives' },
  { id: 'level5', name: 'Level 5', description: 'We continue to validate and refine our corporate, service and AM objectives based on the evolving needs of our community' },
]
const strategies = [
  { id: 'level1', name: 'Level 1', description: 'We have identified the benefits that we want AM to deliver, and the benefits support organisational objectives.' },
  { id: 'level2', name: 'Level 2', description: 'We have a strategy for our AM program and  have a draft roadmap that outlines our approach for the next 1 to 3 years.' },
  { id: 'level3', name: 'Level 3', description: 'We have a  roadmap that details the actions for implementing our AM strategy over the next 3 to 5 years' },
  { id: 'level4', name: 'Level 4', description: 'We are achieving our AM policy objectives. The necessary workflows, documents, and reporting tools are in place and we update our roadmap to address evolving  needs' },
  { id: 'level5', name: 'Level 5', description: 'We follow our roadmap, continually improve our AM practices and we document improvements to our AM practices' },
]
const legals = [
  { id: 'level1', name: 'Level 1', description: 'We have identified Standards, Regulations and Policies that we need to comply with.' },
  { id: 'level2', name: 'Level 2', description: 'We have introduced a programme to ensure compliance' },
  { id: 'level3', name: 'Level 3', description: 'We perform internal assessments and audits to ensure compliance' },
  { id: 'level4', name: 'Level 4', description: 'We perform internal & external assessments and audits to ensure compliance' },
  { id: 'level5', name: 'Level 5', description: 'We comply with all legal, statutory and regulatory requirements' },
]
// ##########################################################################
export default function Governance(props) {
  const [radio1, setRadio1] = useState();
  const [radio2, setRadio2] = useState();
  const [radio3, setRadio3] = useState();

  let navigate = useNavigate();

  const surveyContext = useContext(SurveyContext);  // use the surveyDataContext variable here

function handleNext() {
    if((radio1 === undefined) || (radio2 === undefined) || (radio3 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const governancedata = {radio1, radio2, radio3}
    surveyContext.dispatch({ type: 'ADD_GOVERNANCE', governancedata});
    // console.log(surveyContext.state.surveydata)
    navigate(`/2_leadership`)
  } 
}


return (
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

<AssessmentHeader statusdata={['1']} />
<div>

</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">GOVERNANCE</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
    By developing this competency, your organisation is putting in place AM policies and objectives, bringing those policies to life through a strategy and roadmap. This competency helps you create the policy structure in your organisation that lays out your asset management goals and how they will be achieved, leading to organisational alignment and commitment
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME A: POLICY AND OBJECTIVES
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {policies.map((policy) => (
          <div key={policy.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={policy.id}
                aria-describedby={`${policy.id}-description`}
                name="policy"
                type="radio"
                value={policy.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={policy.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={policy.id} className="font-medium text-gray-700">
                {policy.name}
              </label>
              <p id={`${policy.id}-description`} className="text-gray-500">
                {policy.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME B: STRATEGY AND ROADMAP
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {strategies.map((strategy) => (
          <div key={strategy.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={strategy.id}
                aria-describedby={`${strategy.id}-description`}
                name="strategy"
                type="radio"
                value={strategy.id}
                onChange={(e) => setRadio2(e.target.value)}
                defaultChecked={strategy.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={strategy.id} className="font-medium text-gray-700">
                {strategy.name}
              </label>
              <p id={`${strategy.id}-description`} className="text-gray-500">
                {strategy.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME C: LEGAL OBLIGATIONS
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {legals.map((legal) => (
          <div key={legal.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={legal.id}
                aria-describedby={`${legal.id}-description`}
                name="legal"
                type="radio"
                value={legal.id}
                onChange={(e) => setRadio3(e.target.value)}
                defaultChecked={legal.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={legal.id} className="font-medium text-gray-700">
                {legal.name}
              </label>
              <p id={`${legal.id}-description`} className="text-gray-500">
                {legal.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}
<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
        <button
          type="button"
          onClick={handleNext}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          NEXT
        </button>

      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}