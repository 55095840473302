import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";
// ##########################################################################
const assetplans = [
  { id: 'level1', name: 'Level 1', description: 'We evaluate priorities based on available information, staff experience, and input from council and management.' },
  { id: 'level2', name: 'Level 2', description: 'We have draft AM plans for some asset classes, with forecasted financial needs based on estimated data.' },
  { id: 'level3', name: 'Level 3', description: 'Our AM plans identify short-term issues and priorities.' },
  { id: 'level4', name: 'Level 4', description: 'Our AM plans are based on both short & long-term issues and priorities. They balance short-term service  objectives with longer-term goals and risks.We keep our AM plans up to date through normal business.' },
  { id: 'level5', name: 'Level 5', description: 'Our AM plans include needs forecasts and risk management strategies for most assets. Plans address risks to both service and business goals. Our individual AM  plans are integrated across asset classes.' },
]
// ##########################################################################
export default function AssetPlans(props) {
  const [radio1, setRadio1] = useState();

  let navigate = useNavigate();
  const surveyContext = useContext(SurveyContext);  // use the surveyDataContext variable here

  function handleSave() {
    if((radio1 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const assetplandata = {radio1}
    surveyContext.dispatch({ type: 'ADD_ASSETPLAN', assetplandata});
    navigate(`/save_survey`)
  } 
}

return (
  
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

{/* <AssessmentHeader/> */}

<div>
<AssessmentHeader statusdata={['9']} />
</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">ASSET MANAGEMENT PLANS</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
      By developing this competency, your organisation develops and implements asset management plans, by ensuring that asset management policies, objectives and information are consistently informing organisational plans.	
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME: ASSET MANAGEMENT PLANS
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {assetplans.map((assetplan) => (
          <div key={assetplan.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={assetplan.id}
                aria-describedby={`${assetplan.id}-description`}
                name="assetplan"
                type="radio"
                value={assetplan.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={assetplan.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={assetplan.id} className="font-medium text-gray-700">
                {assetplan.name}
              </label>
              <p id={`${assetplan.id}-description`} className="text-gray-500">
                {assetplan.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}

<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
      <button
          type="button"
          onClick={handleSave}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          SAVE
        </button>
      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}