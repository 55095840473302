import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";
// ##########################################################################

const assetinventory = [
  { id: 'level1', name: 'Level 1', description: 'We have somewhat of an asset inventory.' },
  { id: 'level2', name: 'Level 2', description: 'We have a defined basic inventory of critical assets.' },
  { id: 'level3', name: 'Level 3', description: 'We have a basic inventory of all assets.' },
  { id: 'level4', name: 'Level 4', description: 'We have expanded inventory data of some assets.' },
  { id: 'level5', name: 'Level 5', description: 'We have expanded inventory data of all assets.' },
]
const assetdata = [
  { id: 'level1', name: 'Level 1', description: 'We have approximate quantities of assets within most asset groups.' },
  { id: 'level2', name: 'Level 2', description: 'We have accurate quantities of assets within most asset groups.' },
  { id: 'level3', name: 'Level 3', description: 'We have information on asset attributes such as OEM, Serial No, Year of mfg & commissioning date for critical assets.' },
  { id: 'level4', name: 'Level 4', description: 'We have information on asset attributes such as OEM, Serial No, Year of mfg & commissioning date for most assets.' },
  { id: 'level5', name: 'Level 5', description: 'We have accurate quantities of assets for all asset groups. We have information on asset attributes such as OEM, Serial No, Year of mfg & commissioning date for all assets.' },
]
const assetcondition = [
  { id: 'level1', name: 'Level 1', description: 'We have some info on our asset condition.' },
  { id: 'level2', name: 'Level 2', description: 'We have some info on asset condition of some critical assets.' },
  { id: 'level3', name: 'Level 3', description: 'We have info on asset condition of all critical assets.' },
  { id: 'level4', name: 'Level 4', description: 'We have standardised condition rating systems for all assets.' },
  { id: 'level5', name: 'Level 5', description: 'We have info on asset condition of all assets.' },
]
// ##########################################################################
export default function AssetInformation(props) {
  const [radio1, setRadio1] = useState();
  const [radio2, setRadio2] = useState();
  const [radio3, setRadio3] = useState();

  let navigate = useNavigate();
  const surveyContext = useContext(SurveyContext);  // use the surveyDataContext variable here


function handleNext() {
    if((radio1 === undefined) || (radio2 === undefined) || (radio3 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const assetinfodata = {radio1, radio2, radio3}
    surveyContext.dispatch({ type: 'ADD_ASSETINFORMATION', assetinfodata});
    navigate(`/8_assetlifecycle`)
  } 
}

// function handleBack() {
//   setRadio1('')
//   setRadio2('')
//   setRadio3('')
//   navigate(`/6_standardisation`)
// }

return (
  
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

{/* <AssessmentHeader/> */}

<div>
<AssessmentHeader statusdata={['7']} />
</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">ASSET INFORMATION</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
      By developing this competency, your organisation is using asset information for effective asset management	
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME A: ASSET INVENTORY
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {assetinventory.map((assetinv) => (
          <div key={assetinv.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={assetinv.id}
                aria-describedby={`${assetinv.id}-description`}
                name="assetinv"
                type="radio"
                value={assetinv.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={assetinv.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={assetinv.id} className="font-medium text-gray-700">
                {assetinv.name}
              </label>
              <p id={`${assetinv.id}-description`} className="text-gray-500">
                {assetinv.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME B: ASSET DATA
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {assetdata.map((assetdat) => (
          <div key={assetdat.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={assetdat.id}
                aria-describedby={`${assetdat.id}-description`}
                name="assetdat"
                type="radio"
                value={assetdat.id}
                onChange={(e) => setRadio2(e.target.value)}
                defaultChecked={assetdat.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={assetdat.id} className="font-medium text-gray-700">
                {assetdat.name}
              </label>
              <p id={`${assetdat.id}-description`} className="text-gray-500">
                {assetdat.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME C: ASSET CONDITION
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {assetcondition.map((assetcon) => (
          <div key={assetcon.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={assetcon.id}
                aria-describedby={`${assetcon.id}-description`}
                name="legal"
                type="radio"
                value={assetcon.id}
                onChange={(e) => setRadio3(e.target.value)}
                defaultChecked={assetcon.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={assetcon.id} className="font-medium text-gray-700">
                {assetcon.name}
              </label>
              <p id={`${assetcon.id}-description`} className="text-gray-500">
                {assetcon.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}

<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
      {/* <button
          type="button"
          onClick={handleBack}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          BACK
        </button> */}
      <button
          type="button"
          onClick={handleNext}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          NEXT
        </button>

      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}