import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";
// ##########################################################################

const assetprocess = [
  { id: 'level1', name: 'Level 1', description: 'Our asset management process approaches vary across the organisation.' },
  { id: 'level2', name: 'Level 2', description: 'Our departments follow a similar but informal asset management approach.' },
  { id: 'level3', name: 'Level 3', description: 'We have a structured asset management approach, but application is inconsistent.' },
  { id: 'level4', name: 'Level 4', description: 'We employ a consistent structured asset management approach for each of our critical services.' },
  { id: 'level5', name: 'Level 5', description: 'We employ a consistent structured asset management approach for all services.' },
]

// ##########################################################################
export default function Standardisation(props) {
  const [radio1, setRadio1] = useState();

  let navigate = useNavigate();
  const surveyContext = useContext(SurveyContext);  // use the surveyDataContext variable here


function handleNext() {
    if((radio1 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const standardisationdata = {radio1}
    surveyContext.dispatch({ type: 'ADD_STANDARDISATION', standardisationdata});
    navigate(`/7_assetinformation`)
  } 
}

// function handleBack() {
//   setRadio1('')
//   navigate(`/5_support`)
// }

return (
  
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

{/* <AssessmentHeader/> */}

<div>
<AssessmentHeader statusdata={['6']} />
</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">STANDARDISATION</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
      By developing this competency, your organisation is standardising it's approach to business processes, ensuring that everyone in the value-chain knows who does what and when.	
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME: ASSET BUSINESS PROCESS MANAGEMENT
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {assetprocess.map((asset) => (
          <div key={asset.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={asset.id}
                aria-describedby={`${asset.id}-description`}
                name="asset"
                type="radio"
                value={asset.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={asset.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={asset.id} className="font-medium text-gray-700">
                {asset.name}
              </label>
              <p id={`${asset.id}-description`} className="text-gray-500">
                {asset.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}

<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
      {/* <button
          type="button"
          onClick={handleBack}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          BACK
        </button> */}
        <button
          type="button"
          onClick={handleNext}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          NEXT
        </button>

      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}