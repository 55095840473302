import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";
// ##########################################################################
const financeinformation = [
  { id: 'level1', name: 'Level 1', description: 'We have financial information on our assets, supporting minimum reporting requirements.' },
  { id: 'level2', name: 'Level 2', description: 'We have major capital renewal and operating & maintenance expenditure data for some assets. We have a strategy to link AM and financial information.' },
  { id: 'level3', name: 'Level 3', description: 'We have CAPEX (new & renewal) and OPEX expenditure data for most assets. We have linked AM and financial information for all critical assets. We can demonstrate the gaps between forecasted infrastructure needs and current spending levels.' },
  { id: 'level4', name: 'Level 4', description: 'We understand the cost of sustaining current levels of service for all critical assets.' },
  { id: 'level5', name: 'Level 5', description: 'We understand the trade-offs between investment and the level of service we deliver and use this to optimize our financial plans.' },
]
const budgeting = [
  { id: 'level1', name: 'Level 1', description: 'We prepare annual capital and operating budgets based on historical values.' },
  { id: 'level2', name: 'Level 2', description: 'We prepare annual capital and operating budgets based on a mix of historical values and new priorities.' },
  { id: 'level3', name: 'Level 3', description: 'We prepare an annual capital budget based on an annual assessment of current needs.' },
  { id: 'level4', name: 'Level 4', description: 'We prepare annual needs based capital & operating budgets that are based on an annual assessment of risks and current needs.' },
  { id: 'level5', name: 'Level 5', description: 'We prepare multi-year needs-based capital & operating budgets that are based on our short and mid-term needs.' },
]
const financeplanning = [
  { id: 'level1', name: 'Level 1', description: 'We deal with new needs reactively, as they occur.' },
  { id: 'level2', name: 'Level 2', description: 'We prepare an annual Capex & Opex budget based on current needs.' },
  { id: 'level3', name: 'Level 3', description: 'We have a 3-year Capex & Opex plan that addresses short-term issues and priorities.' },
  { id: 'level4', name: 'Level 4', description: 'We have a 5 year Capex & Opex plan and update it annually.' },
  { id: 'level5', name: 'Level 5', description: 'We update our long-term financial plan (at least 10-year) annually and understand the risks associated with our investment gap.' },
]
// ##########################################################################
export default function Finance(props) {
  const [radio1, setRadio1] = useState();
  const [radio2, setRadio2] = useState();
  const [radio3, setRadio3] = useState();

let navigate = useNavigate();
  const surveyContext = useContext(SurveyContext);  // use the surveyDataContext variable here

function handleNext() {
    if((radio1 === undefined) || (radio2 === undefined) || (radio3 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const financedata = {radio1, radio2, radio3}
    surveyContext.dispatch({ type: 'ADD_FINANCE', financedata});
    navigate(`/5_support`)
  } 
}

// function handleBack() {
//   setRadio1('')
//   setRadio2('')
//   setRadio3('')
//   navigate(`/3_measurement`)
// }

return (
  
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

{/* <AssessmentHeader/> */}

<div>
<AssessmentHeader statusdata={['4']} />
</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">FINANCE</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
      By developing this competency, your organisation sets asset management priorities, conducts capital, operations and maintenance planning, and decides on budgets.	
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME A: FINANCE INFORMATION
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {financeinformation.map((financeinfo) => (
          <div key={financeinfo.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={financeinfo.id}
                aria-describedby={`${financeinfo.id}-description`}
                name="financeinfo"
                type="radio"
                value={financeinfo.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={financeinfo.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={financeinfo.id} className="font-medium text-gray-700">
                {financeinfo.name}
              </label>
              <p id={`${financeinfo.id}-description`} className="text-gray-500">
                {financeinfo.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME B: BUDGETING
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {budgeting.map((budget) => (
          <div key={budget.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={budget.id}
                aria-describedby={`${budget.id}-description`}
                name="budget"
                type="radio"
                value={budget.id}
                onChange={(e) => setRadio2(e.target.value)}
                defaultChecked={budget.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={budget.id} className="font-medium text-gray-700">
                {budget.name}
              </label>
              <p id={`${budget.id}-description`} className="text-gray-500">
                {budget.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME C: FINANCIAL PLANNING
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {financeplanning.map((financeplan) => (
          <div key={financeplan.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={financeplan.id}
                aria-describedby={`${financeplan.id}-description`}
                name="legal"
                type="radio"
                value={financeplan.id}
                onChange={(e) => setRadio3(e.target.value)}
                defaultChecked={financeplan.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={financeplan.id} className="font-medium text-gray-700">
                {financeplan.name}
              </label>
              <p id={`${financeplan.id}-description`} className="text-gray-500">
                {financeplan.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}

<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
      {/* <button
          type="button"
          onClick={handleBack}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          BACK
        </button> */}
      <button
          type="button"
          onClick={handleNext}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          NEXT
        </button>

      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}