
import Assessor from '../components/0_assessor';


// ####################### REDUCER FUNCTION STARTS HERE ##############################


export default function NewSurvey() {


 

  return (
    <div>

      <Assessor/>

    </div>
  )
}