import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import SurveyContext from "../context/SurveyContext";

//#################################################################################################################
export default function Assessor() {
let navigate = useNavigate();

const surveyContext = useContext(SurveyContext);
const [newassessment, setNewAssessment] = useState({ 
  clientID: useContext(UserContext),
  assessorname: '', 
  assessornameaddress: '', 
  assessoremail: '', 
  assessornumber: '', 
  assessordescription: '',
})

//______________________________________________
const handleNext = () => {
  // console.log(newassessment)
  if ((newassessment.assessorname === '') || (newassessment.assessordescription === '') || (newassessment.assessoremail === '') || (newassessment.assessornameaddress === '') || (newassessment.assessornumber === '')) {
    alert("PLEASE ADD ALL FIELDS")
    } 
    else {
      surveyContext.dispatch({ type: 'ADD_ASSESSOR', newassessment});
      navigate(`/1_governance`)
  }
}

//#################################################################################################################
return (
    <div className="min-h-screen bg-gray-900 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div>
{/* <AssessmentHeader statusdata={['0']} /> */}
</div>

    <div className="bg-white py-10 px-4 shadow sm:rounded-lg sm:px-10">
{/* //________________________________________________________________________ */} 
        <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  value={newassessment.assessorname}
                  onChange={e => setNewAssessment({ ...newassessment, assessorname: e.target.value })}
                  autoComplete="text"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
        </div>
{/* //________________________________________________________________________ */}            
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Address
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  value={newassessment.assessornameaddress}
                  onChange={e => setNewAssessment({ ...newassessment, assessornameaddress: e.target.value })}
                  autoComplete="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
{/* //________________________________________________________________________ */}            
<div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  value={newassessment.assessoremail}
                  onChange={e => setNewAssessment({ ...newassessment, assessoremail: e.target.value })}
                  autoComplete="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
{/* //________________________________________________________________________ */}            
<div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Contact Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  value={newassessment.assessornumber}
                  onChange={e => setNewAssessment({ ...newassessment, assessornumber: e.target.value })}
                  autoComplete="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
{/* //________________________________________________________________________ */}            
<div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  value={newassessment.assessordescription}
                  onChange={e => setNewAssessment({ ...newassessment, assessordescription: e.target.value })}
                  autoComplete="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
{/* //________________________________________________________________________ */}      
          <div className="grid gap-4 grid-cols-2 py-2">
           <button
             type="submit"
            onClick={handleNext}
            // onClick={() => {navigate(`/1_governance`)}}

            className="inline-flex text-center items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
             NEXT
           </button>
         </div>
{/* //________________________________________________________________________ */}            

       <div className="mt-6">
         <div className="relative">
           <div className="absolute inset-0 flex items-center">
             <div className="w-full border-t border-gray-300" />
           </div>
         </div>

         <div className="mt-6 grid grid-cols-3 gap-3">
 

           <div>

           </div>
         </div>
       </div>
     </div>
   </div>
 </div>


 )
}