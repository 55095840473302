import { useEffect, useState, useContext } from "react";
import SurveyContext from "../context/SurveyContext";
import useAssessment from '../context/assessmentcontext'
import { useNavigate } from "react-router-dom";

//####################################################################
export default function TestPage() {
const surveyContext = useContext(SurveyContext);
const { theassessments, addAssessments  } = useAssessment();

let navigate = useNavigate();


//###################################### USE EFFECTS START ###############################################
async function handleTEST() {
  addAssessments()
  navigate(`/test2page`)

  }
//####################################################################
return (
<div className="bg-gray-100 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}
{/* __________________________________________________________________________________ */}

      <button
        onClick= { () => handleTEST() }
        type="button"
        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        ADD 5
      </button>

      <div>
        <h1>
          {theassessments}
        </h1>
      </div>
      

{/* __________________________________________________________________________________ */}

</div>
</div>
);
}

