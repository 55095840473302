import { createContext, useReducer, useContext } from "react";
import assessmentReducer, { initialState } from '../reducers/assessmentreducer';
const AssessmentContext = createContext(initialState);

export const AssessmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(assessmentReducer, initialState);

  const addAssessments = (datain) => {
    dispatch({
        type: "add_assessments",
      payload: datain
    });
  };

  const getAssessments = () => {
    dispatch({
        type: "get_assessments",
      payload: 0
    });
  };

  const value = {
    theassessments: state,
    addAssessments,
    getAssessments
  };


  return <AssessmentContext.Provider value={value}>{children}</AssessmentContext.Provider>;
};

// #########################################################################
const useAssessment = () => {
  const context = useContext(AssessmentContext);
  if (context === undefined) {
    throw new Error("useShop must be used within ShopContext");
  }
  return context;
};

export default useAssessment;