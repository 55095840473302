import { useEffect, useState, useContext } from "react";
import SurveyContext from "../context/SurveyContext";
import useAssessment from '../context/assessmentcontext'
import myrubric from '../constants/myrubric'
// import {policies, strategies, legals, teams, accountabilities, commitments, progressm, performancem, financeinformation, budgeting, financeplanning,   training, knowledgetransfer, changemanagement, assetprocess, assetinventory, assetdata, assetcondition, investmentplanning, assetrenewal, assetmaintenance, assetplans}  from '../constants/myconstants'
import ViewGovernance from "../components/viewresults/viewgovernance";
import ReactLoading from "react-loading";


// import { CalendarIcon, MapPinIcon, UsersIcon } from '@heroicons/react/20/solid'
//####################################################################

export default function ViewAssessments() {
const surveyContext = useContext(SurveyContext);
const { theassessments, addAssessments  } = useAssessment();
const [assessments, setAssessments] = useState([]);
const [isloading, setIsLoading] = useState(true);
const optioncount = [3,3,1,3,3,3,2,1,3]
const [stats, setStats] = useState([]);
const [selectdata, setSelectdata] = useState();

const therows = theassessments.length  //12 for our example
const thecolumns = 10;

useEffect(() => { 
  // console.log(theassessments)
  const thestats = [];
  var matrix = new Array(therows).fill(0).map(() => new Array(thecolumns).fill(0));
       for (let i = 0; i < therows; i++) {
            // console.log(theassessments[i])
            matrix[i][0] = JSON.parse(theassessments[i].assetinfo) 
            matrix[i][1] = JSON.parse(theassessments[i].assetlifecycle) 
            matrix[i][2] = JSON.parse(theassessments[i].assetplan) 
            matrix[i][3] = JSON.parse(theassessments[i].finance) 
            matrix[i][4] = JSON.parse(theassessments[i].governance) 
            matrix[i][5] = JSON.parse(theassessments[i].leadership) 
            matrix[i][6] = JSON.parse(theassessments[i].measurement) 
            matrix[i][7] = JSON.parse(theassessments[i].standardisation) 
            matrix[i][8] = JSON.parse(theassessments[i].support) 
            matrix[i][9] = calcAssetScore(matrix[i]);
            const theassessordetails = JSON.parse(theassessments[i].assessordata);
            thestats.push({
              id:  theassessments[i].id,
              date: theassessments[i].createdAt,
              name: theassessordetails.assessorname,
              description:  theassessordetails.assessordescription,
              assetinfo: matrix[i][0],
              assetlifecycle:matrix[i][1],
              assetplan:matrix[i][2],
              finance:matrix[i][3],
              governance:matrix[i][4],
              leadership: matrix[i][5],
              measurement: matrix[i][6],
              standardisation: matrix[i][7],
              support: matrix[i][8],
              stat: matrix[i][9]
            })
      }
      
      setAssessments(matrix)
      // console.log(thestats)
      setIsLoading(false)
      setStats(thestats)
  }, []);

//______________________________________________
function calcAssetScore(thedata) {
var finalscore = 0;
var competancyscore = 0;
var competancytotal = 0;

// console.log(thedata)

  for (let j = 0; j < thecolumns-1; j++) {
      const str = JSON.stringify(thedata[j]);
      var str_array = str.split('"')
      for(var k = 0; k < (str_array.length-1); k++) 
        {
          let text = str_array[k];
          let result = text.includes("level");
              if (result == true) {
                const c = text.slice(-1)
                competancytotal = competancytotal + Number(c)
              }
        }
        competancyscore = Math.round((20*(competancytotal/optioncount[j])))
        // console.log(competancyscore)
        finalscore = finalscore + competancyscore
        competancytotal = 0;
        competancyscore = 0;
    }
    finalscore = Math.round(finalscore/9);
return finalscore;

}

const handleViewAssessments = (selectedid) => {
    const filteredAssessment = stats.filter(item => item.id === selectedid);
    console.log(filteredAssessment)
    setSelectdata(filteredAssessment)
}



//####################################################################
return (
  <>
        {isloading ? (
          <ReactLoading
            type={"bubbles"}
            color={"#120A0A"}
            height={100}
            width={100}
          />
        ) : (

  //######################## REACT LOADING ENDS HERE #################################
  <div className="bg-neutral-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
  <div className="grid grid-cols-2 gap-2">  {/* The grid starts here */}
  {/* #################################################################### */}
    <div>
      {/* <h3 className="text-lg font-medium leading-6 text-gray-900">ASSESSMENT HISTORY</h3> */}

      <div className="max-w-3xl">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900">
              ASSESSMENTS
            </h2>

          </div>

      <dl className="mt-5 grid grid-cols-1 gap-5 ">
        {stats.map((item) => (
          <div key={item.id} className="overflow-hidden rounded-lg bg-neutral-800 px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-red-900">SCORE - {item.stat}</dd>
            <button
                onClick= { () => handleViewAssessments(item.id) }
                type="button"
                className="inline-flex items-center rounded border border-gray-800 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:text-gray-100 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                VIEW DETAIL
            </button>
            <dt className="truncate text-sm font-medium text-gray-500">{item.date}</dt>

          </div>
        ))}
      </dl>
    </div>

  {/* ___________________________________________________________________ */}

  <div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}
  <ViewGovernance data = {selectdata}/>
</div>


    {/* #################################################################### */}

      </div>
      </div>
  
        )}
      </>
  
  
  );
  }