export const initialState = 0;

const assessmentReducer = (state, action) => {
  const { type, payload } = action;
    switch (type) {
        case 'add_assessments':
          state = payload;
          // console.log(payload)
            return state
 
            case 'get_assessments':
              // console.log(state)
                return state
          
          default:
            return state
    }
  };
  
  export default assessmentReducer;
  