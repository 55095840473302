import { CheckCircleIcon } from '@heroicons/react/solid'

// { name: 'Governance', href: '\governance', status: 'complete' },
// { name: 'Leadership', href: '#', status: 'current' },

const steps = [
  { name: 'Governance', href: '/governance', status: 'upcoming' },
  { name: 'Leadership', href: '#', status: 'upcoming' },
  { name: 'Measurement and Monitoring', href: '#', status: 'upcoming' },
  { name: 'Finance', href: '#', status: 'upcoming' },
  { name: 'Support', href: '#', status: 'upcoming' },
  { name: 'Standardisation', href: '#', status: 'upcoming' },
  { name: 'Asset Information', href: '#', status: 'upcoming' },
  { name: 'Asset Lifecycle', href: '#', status: 'upcoming' },
  { name: 'Asset Management Plans', href: '#', status: 'upcoming' },
]
//####################################################### FUNCTIONS ##############
export default function Assessment() {
  return (
    <div className="bg-gray-600 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-2 gap-10">  {/* The grid starts here */}
{/* __________________________________________________________________________________ */}
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <nav className="flex justify-center" aria-label="Progress">
        <ol role="list" className="space-y-6">
          {steps.map((step) => (
            <li key={step.name}>
              {step.status === 'complete' ? (
                <a href={step.href} className="group">
                  <span className="flex items-start">
                    <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                      <CheckCircleIcon
                        className="h-full w-full text-gray-100 group-hover:text-green-100"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-100 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </a>
              ) : step.status === 'current' ? (
                <a href={step.href} className="flex items-start" aria-current="step">
                  <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                    <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                    <span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
                  </span>
                  <span className="ml-3 text-sm font-medium text-gray-100">{step.name}</span>
                </a>
              ) : (
                <a href={step.href} className="group">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                      <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                    </div>
                    <p className="ml-3 text-sm font-medium text-gray-100 group-hover:text-gray-900">{step.name}</p>
                  </div>
                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>


        <div className="py-12 px-4 sm:px-6 lg:px- space-x-3.5 content-center">
              <button
                // onClick={handleSave}
                type="submit"
                className="bg-green-500 text-gray-100 rounded hover:bg-green-400 px-2 py-2 focus:outline-none">              
                  SAVE
              </button>
              <button
                // onClick={handleSave}
                type="submit"
                className="bg-red-500 text-gray-100 rounded hover:bg-red-400 px-2 py-2 focus:outline-none">              
                  CANCEL
              </button>
        </div>

    {/* __________________________________________________________________________________ */}
</div>
</div>

  )
}