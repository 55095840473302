import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";
// ##########################################################################

const investmentplanning = [
  { id: 'level1', name: 'Level 1', description: 'Our asset investment planning approaches vary across the organisation.' },
  { id: 'level2', name: 'Level 2', description: 'We evaluate investment needs and priorities based on a mix of structured and ad-hoc practices and criteria.' },
  { id: 'level3', name: 'Level 3', description: 'We set priorities using criteria based on organisational goals and objectives.' },
  { id: 'level4', name: 'Level 4', description: 'We set priorities using criteria that are fully aligned with our organisational goals and objectives.' },
  { id: 'level5', name: 'Level 5', description: 'We adapt our planning approach and criteria to align with evolving organisational goals and objectives.' },
]
const assetrenewal = [
  { id: 'level1', name: 'Level 1', description: 'Our approach to Asset Renewal focuses on reacting to basic needs (e.g. growth, regulations and known problems).' },
  { id: 'level2', name: 'Level 2', description: 'We have draft Asset Renewal plans for some asset classes, with forecasted financial needs based on estimated data.' },
  { id: 'level3', name: 'Level 3', description: 'We have Asset Renewal plans for critical assets/services, based on a mix of estimated and actual data.' },
  { id: 'level4', name: 'Level 4', description: 'We have Asset Renewal plans for most assets and services based on actual data. Our plans include forecasting for basic needs and risk management strategies for critical assets.' },
  { id: 'level5', name: 'Level 5', description: 'We have Asset Renewal plans for all services based on actual data. Our individual plans are integrated across services/assets.' },
]
const assetmaintenance = [
  { id: 'level1', name: 'Level 1', description: 'Maintenance interventions are mostly reactive and run-to-failure. Our maintenance approaches vary across assets.' },
  { id: 'level2', name: 'Level 2', description: 'We have a combination of reactive and time based maintenance approach for some of our assets.' },
  { id: 'level3', name: 'Level 3', description: 'We have a well defined maintenance programme for all our critical assets.' },
  { id: 'level4', name: 'Level 4', description: 'We have a well defined preventative-maintenance programme for all our assets.' },
  { id: 'level5', name: 'Level 5', description: 'We use predictive maintenance techniques (condition-monitoring, etc) to prevent failure on selected asets.' },
]
// ##########################################################################
export default function AssetLifeCycle(props) {
  const [radio1, setRadio1] = useState();
  const [radio2, setRadio2] = useState();
  const [radio3, setRadio3] = useState();

  let navigate = useNavigate();
  const surveyContext = useContext(SurveyContext);  // use the surveyDataContext variable here


function handleNext() {
    if((radio1 === undefined) || (radio2 === undefined) || (radio3 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const assetlifecycledata = {radio1, radio2, radio3}
    surveyContext.dispatch({ type: 'ADD_ASSETLIFECYCLE', assetlifecycledata});
    navigate(`/9_assetplans`)
  } 
}

// function handleBack() {
//   setRadio1('')
//   setRadio2('')
//   setRadio3('')
//   navigate(`/7_assetinformation`)
// }

return (
  
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

{/* <AssessmentHeader/> */}

<div>
<AssessmentHeader statusdata={['8']} />
</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">ASSET LIFECYCLE</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
      By developing this competency, your organisation conducts Capex, Operations and Maintenance (Opex) planning, and influences budgeting processes
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME A: INVESTMENT PLANNING (CAPEX)
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {investmentplanning.map((investmentplan) => (
          <div key={investmentplan.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={investmentplan.id}
                aria-describedby={`${investmentplan.id}-description`}
                name="investmentplan"
                type="radio"
                value={investmentplan.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={investmentplan.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={investmentplan.id} className="font-medium text-gray-700">
                {investmentplan.name}
              </label>
              <p id={`${investmentplan.id}-description`} className="text-gray-500">
                {investmentplan.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME B: ASSET RENEWAL
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {assetrenewal.map((assetren) => (
          <div key={assetren.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={assetren.id}
                aria-describedby={`${assetren.id}-description`}
                name="assetren"
                type="radio"
                value={assetren.id}
                onChange={(e) => setRadio2(e.target.value)}
                defaultChecked={assetren.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={assetren.id} className="font-medium text-gray-700">
                {assetren.name}
              </label>
              <p id={`${assetren.id}-description`} className="text-gray-500">
                {assetren.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME C: ASSET MAINTENANCE (OPEX)
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {assetmaintenance.map((assetmaint) => (
          <div key={assetmaint.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={assetmaint.id}
                aria-describedby={`${assetmaint.id}-description`}
                name="assetmaint"
                type="radio"
                value={assetmaint.id}
                onChange={(e) => setRadio3(e.target.value)}
                defaultChecked={assetmaint.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={assetmaint.id} className="font-medium text-gray-700">
                {assetmaint.name}
              </label>
              <p id={`${assetmaint.id}-description`} className="text-gray-500">
                {assetmaint.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}

<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
      {/* <button
          type="button"
          onClick={handleBack}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          BACK
        </button> */}
      <button
          type="button"
          onClick={handleNext}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          NEXT
        </button>

      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}