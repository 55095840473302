import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";

// ##########################################################################

const teams = [
  { id: 'level1', name: 'Level 1', description: 'We have identified the representation we need on our cross-functional AM team.' },
  { id: 'level2', name: 'Level 2', description: 'We have a cross-functional AM team that guides the planning and implementation of our AM program.' },
  { id: 'level3', name: 'Level 3', description: 'Our AM team works within our organization to lead, communicate, and support AM improvements and organizational changes.' },
  { id: 'level4', name: 'Level 4', description: 'Our AM team is permanent and tasked with guiding and supporting AM across the organisation on an ongoing basis.' },
  { id: 'level5', name: 'Level 5', description: 'Our AM team guides and supports the ongoing improvement of AM within the organisation.' },
]
const accountabilities = [
  { id: 'level1', name: 'Level 1', description: 'We have a champion who has been tasked with planning our AM program.' },
  { id: 'level2', name: 'Level 2', description: 'Our AM team has a documented mandate to develop our AM program, which is outlined in a terms of reference and a one to three-year roadmap.' },
  { id: 'level3', name: 'Level 3', description: 'Our AM team is accountable to Snr. Management and Council and for implementing our AM program. AM roles and responsibilities are included in staff job descriptions.' },
  { id: 'level4', name: 'Level 4', description: 'We have operationalised AM roles and responsibilities across our organisation.' },
  { id: 'level5', name: 'Level 5', description: 'We document changes to AM roles and responsibilities as needed to support our evolving requirements.' },
]
const commitments = [
  { id: 'level1', name: 'Level 1', description: 'Council knows that resources must be dedicated to exploring the requirements for AM and for drafting an AM roadmap.' },
  { id: 'level2', name: 'Level 2', description: 'Council demonstrates buy-in and support for AM and allocates resources (funding or staff time) to further develop the AM program.' },
  { id: 'level3', name: 'Level 3', description: 'Council champions AM as a core business function and has approved funding to continue AM roadmap activities.' },
  { id: 'level4', name: 'Level 4', description: 'Council funds ongoing AM monitoring and enhancement.' },
  { id: 'level5', name: 'Level 5', description: 'Council demonstrates commitment to ongoing improvement of AM practices.' },
]
// ##########################################################################
export default function Leadership(props) {
  const [radio1, setRadio1] = useState();
  const [radio2, setRadio2] = useState();
  const [radio3, setRadio3] = useState();

  let navigate = useNavigate();
  const surveyContext = useContext(SurveyContext);  // use the surveyDataContext variable here


function handleNext() {
    if((radio1 === undefined) || (radio2 === undefined) || (radio3 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const leadershipdata = {radio1, radio2, radio3}
    // console.log(leadershipdata)  
    surveyContext.dispatch({ type: 'ADD_LEADERSHIP', leadershipdata});
    navigate(`/3_measurement`)
  } 
}

// function handleBack() {
//   setRadio1('')
//   setRadio2('')
//   setRadio3('')
//   navigate(`/governance`)
// }

return (
  
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

{/* <AssessmentHeader/> */}

<div>
<AssessmentHeader statusdata={['2']} />
</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">LEADERSHIP</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
      By developing this competency, your organisation is setting up cross-functional teams with clear accountability and ensuring adequate resourcing and commitment from senior management and elected officials to advance asset management.	
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME A: CROSS FUNCTIONAL TEAMS
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {teams.map((team) => (
          <div key={team.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={team.id}
                aria-describedby={`${team.id}-description`}
                name="team"
                type="radio"
                value={team.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={team.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={team.id} className="font-medium text-gray-700">
                {team.name}
              </label>
              <p id={`${team.id}-description`} className="text-gray-500">
                {team.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME B: ACCOUNTABILITY
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {accountabilities.map((accountability) => (
          <div key={accountability.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={accountability.id}
                aria-describedby={`${accountability.id}-description`}
                name="accountability"
                type="radio"
                value={accountability.id}
                onChange={(e) => setRadio2(e.target.value)}
                defaultChecked={accountability.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={accountability.id} className="font-medium text-gray-700">
                {accountability.name}
              </label>
              <p id={`${accountability.id}-description`} className="text-gray-500">
                {accountability.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME C: COMMITMENT
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {commitments.map((commitment) => (
          <div key={commitment.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={commitment.id}
                aria-describedby={`${commitment.id}-description`}
                name="legal"
                type="radio"
                value={commitment.id}
                onChange={(e) => setRadio3(e.target.value)}
                defaultChecked={commitment.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={commitment.id} className="font-medium text-gray-700">
                {commitment.name}
              </label>
              <p id={`${commitment.id}-description`} className="text-gray-500">
                {commitment.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}

<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
      {/* <button
          type="button"
          onClick={handleBack}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          BACK
        </button> */}
      <button
          type="button"
          onClick={handleNext}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          NEXT
        </button>

      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}