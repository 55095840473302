

//this is the scoring matrix used

const myrubric = [
    { outcomea: 5, outcomeb: 5, outcomec: 5 },
    { outcomea: 5, outcomeb: 5, outcomec: 5 },
    { outcomea: 5, outcomeb: 5 },
    { outcomea: 5, outcomeb: 5, outcomec: 5 },
    { outcomea: 5, outcomeb: 5, outcomec: 5 },
    { outcomea: 5 },
    { outcomea: 5, outcomeb: 5, outcomec: 5 },
    { outcomea: 5, outcomeb: 5, outcomec: 5 },
    { outcomea: 5 }
  ]
  
export default myrubric;