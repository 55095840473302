import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";
// ##########################################################################

const progressm = [
  { id: 'level1', name: 'Level 1', description: 'We have identified short-term actions that will demonstrate early progress on AM.' },
  { id: 'level2', name: 'Level 2', description: 'We are collecting baseline data on our current AM practices.' },
  { id: 'level3', name: 'Level 3', description: 'We have established performance measures to monitor our asset management progress, outcomes & the benefits.' },
  { id: 'level4', name: 'Level 4', description: 'We use performance measures to monitor AM progress, outcomes & benefits.' },
  { id: 'level5', name: 'Level 5', description: 'We monitor performance and use the feedback to prioritise and make ongoing refinements and improvements to AM practices.' },
]
const performancem = [
  { id: 'level1', name: 'Level 1', description: 'We have an informal approach for measuring asset or service performance.' },
  { id: 'level2', name: 'Level 2', description: 'We have some information on performance of critical assets, collected from a various sources.' },
  { id: 'level3', name: 'Level 3', description: 'We have defined level of service & performance measurements and have captured data on current level of service & performance for some areas.' },
  { id: 'level4', name: 'Level 4', description: 'We have defined level of service measurements for critical service areas.' },
  { id: 'level5', name: 'Level 5', description: 'We have defined level of service measurements for most or all service areas.' },
]

// ##########################################################################
export default function Measurement(props) {
  const [radio1, setRadio1] = useState();
  const [radio2, setRadio2] = useState();

  let navigate = useNavigate();
  const surveyDataContext = useContext(SurveyContext);  // use the surveyDataContext variable here


function handleNext() {
    if((radio1 === undefined) || (radio2 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const measurementdata = {radio1, radio2}
    // console.log(newgovernance)  
    surveyDataContext.dispatch({ type: 'ADD_MEASUREMENT', measurementdata});
    navigate(`/4_finance`)
  } 
}

// function handleBack() {
//   setRadio1('')
//   setRadio2('')
//   navigate(`/2_leadership`)
// }

return (
  
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

{/* <AssessmentHeader/> */}

<div>
<AssessmentHeader statusdata={['3']} />
</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">MEASUREMENT AND MONITORING</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
      By developing this competency, your organization is putting in place actions for measuring AM progress and monitoring AM implementation over time. Also your organization is collecting and using asset performance data to support effective asset management planning and decision-making.	
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME A: PROGRESS MONITORING
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {progressm.map((progress) => (
          <div key={progress.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={progress.id}
                aria-describedby={`${progress.id}-description`}
                name="progress"
                type="radio"
                value={progress.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={progress.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={progress.id} className="font-medium text-gray-700">
                {progress.name}
              </label>
              <p id={`${progress.id}-description`} className="text-gray-500">
                {progress.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME B: PERFORMANCE MONITORING
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {performancem.map((performance) => (
          <div key={performance.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={performance.id}
                aria-describedby={`${performance.id}-description`}
                name="performance"
                type="radio"
                value={performance.id}
                onChange={(e) => setRadio2(e.target.value)}
                defaultChecked={performance.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={performance.id} className="font-medium text-gray-700">
                {performance.name}
              </label>
              <p id={`${performance.id}-description`} className="text-gray-500">
                {performance.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>


{/* __________________________________________________________________________________ */}

<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
      {/* <button
          type="button"
          onClick={handleBack}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          BACK
        </button> */}
        <button
          type="button"
          onClick={handleNext}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          NEXT
        </button>

      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}