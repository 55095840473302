function surveyReducer(state, action) {
  switch(action.type) {
    
    case 'ADD_ASSESSOR':
      const myassessor =     {
          assessordescription: action.newassessment.assessordescription,
          assessoremail: action.newassessment.assessoremail,
          assessorname: action.newassessment.assessorname,
          assessornameaddress: action.newassessment.assessornameaddress,
          assessornumber: action.newassessment.assessornumber,
          clientID: action.newassessment.clientID,
        }
        const newassessor = JSON.stringify(myassessor);
        return {...state,
          assessordata: newassessor};
  
        // _____________________________________________________
    case 'ADD_GOVERNANCE':
      const mygovernance =     {
          option1selection: action.governancedata.radio1,
          option2selection: action.governancedata.radio2,
          option3selection: action.governancedata.radio3
        }
        const newgovernance = JSON.stringify(mygovernance)
        return {...state,
          governance: newgovernance};
       
  // _____________________________________________________
    case 'ADD_LEADERSHIP':
      // console.log(state.surveydata);
      const myleadership =    {
      option1selection: action.leadershipdata.radio1,
      option2selection: action.leadershipdata.radio2,
      option3selection: action.leadershipdata.radio3
    }
    const newleadership = JSON.stringify(myleadership);

    return {...state,
      leadership: newleadership};
// _____________________________________________________
    case 'ADD_MEASUREMENT':
      const mymeasurement =    {
      option1selection: action.measurementdata.radio1,
      option2selection: action.measurementdata.radio2
    }
    const newmeasurement = JSON.stringify(mymeasurement);

    return {...state,
      measurement: newmeasurement};

// _____________________________________________________
    case 'ADD_FINANCE':
      const myfinance =    {
        option1selection: action.financedata.radio1,
        option2selection: action.financedata.radio2,
        option3selection: action.financedata.radio3
      }
      const newfinance = JSON.stringify(myfinance);
    
      return {...state,
        finance: newfinance};

// _____________________________________________________
  case 'ADD_SUPPORT':
    const mysupport =    {
    option1selection: action.supportdata.radio1,
    option2selection: action.supportdata.radio2,
    option3selection: action.supportdata.radio3
  }
  const newsupport = JSON.stringify(mysupport);

  return {...state,
    support: newsupport};

// _____________________________________________________
    case 'ADD_STANDARDISATION':
      const mystandardisation =    {
      option1selection: action.standardisationdata.radio1,
      option2selection: action.standardisationdata.radio2
    }
    const newstandardisation = JSON.stringify(mystandardisation);
    return {...state,
      standardisation: newstandardisation};

// _____________________________________________________
    case 'ADD_ASSETINFORMATION':
      console.log(action.assetinfodata)
      const myassetinformation =    {
      option1selection: action.assetinfodata.radio1,
      option2selection: action.assetinfodata.radio2,
      option3selection: action.assetinfodata.radio3
    }
    console.log(myassetinformation)
    const newassetinformation = JSON.stringify(myassetinformation);

    return {...state,
      assetinformation: newassetinformation};
// _____________________________________________________
   case 'ADD_ASSETLIFECYCLE':
    const myassetlifecycle =    {
    option1selection: action.assetlifecycledata.radio1,
    option2selection: action.assetlifecycledata.radio2,
    option3selection: action.assetlifecycledata.radio3
  }
  const newassetlifecycle = JSON.stringify(myassetlifecycle);
  return {...state,
    assetlifecycle: newassetlifecycle};
// _____________________________________________________
   case 'ADD_ASSETPLAN':
    const myassetplan =    {
    option1selection: action.assetplandata.radio1,
  }
  const newassetplan = JSON.stringify(myassetplan);
  return {...state,
    assetplan: newassetplan};

// _____________________________________________________
  case 'ASSESSMENTSDDB':
      return {...state,
      historicassessments: action.testdata}; 
// _____________________________________________________
    case 'RESET_SURVEY':
    return {...state,
      assessordata:  "",
      governance: "",
      leadership:  "",
      measurement:  "",
      finance:  "",
      support:  "",
      standardisation:  "",
      assetinfo:  "",
      assetlifecycle:  "",
      assetplan:  "",
    }
    // _____________________________________________________
    default:
      return state;
  
  } }

  export default surveyReducer;
