// import { useEffect } from 'react';
import { CheckIcon } from '@heroicons/react/solid'
// ###################################################################################

// const steps = [
//   { id: '01', name: 'Step1', href: '/0_assessor', status: 'upcoming' },
//   { id: '02', name: 'Step2', href: '/1_governance', status: 'upcoming' },
//   { id: '03', name: 'Step3', href: '/2_leadership', status: 'upcoming' },
//   { id: '04', name: 'Step4', href: '/3_measurement', status: 'upcoming' },
//   { id: '05', name: 'Step5', href: '/4_finance', status: 'upcoming' },
//   { id: '06', name: 'Step6', href: '/5_support', status: 'upcoming' },
//   { id: '07', name: 'Step7', href: '/6_standardisation', status: 'upcoming' },
//   { id: '08', name: 'Step8', href: '/7_assetinformation', status: 'upcoming' },
//   { id: '09', name: 'Step9', href: '/8_assetlifecycle', status: 'upcoming' },
//   { id: '10', name: 'Step10', href: '/9_assetplans', status: 'upcoming' },
// ]

const steps = [
  { id: '01', name: 'Step1', href: '', status: 'upcoming' },
  { id: '02', name: 'Step2', href: '', status: 'upcoming' },
  { id: '03', name: 'Step3', href: '', status: 'upcoming' },
  { id: '04', name: 'Step4', href: '', status: 'upcoming' },
  { id: '05', name: 'Step5', href: '', status: 'upcoming' },
  { id: '06', name: 'Step6', href: '', status: 'upcoming' },
  { id: '07', name: 'Step7', href: '', status: 'upcoming' },
  { id: '08', name: 'Step8', href: '', status: 'upcoming' },
  { id: '09', name: 'Step9', href: '', status: 'upcoming' },
  { id: '10', name: 'Step10', href: '', status: 'upcoming' },
]

// ###################################################################################
export default function AssessmentHeader({ statusdata }) {
  
  var stepnumber = Number(statusdata);
  for (let i = 0; i < 9; i++) {
    if (stepnumber < i){
      steps[i].status = "upcoming"
    }
  if (stepnumber == i){
    steps[i].status = "current"
  }
  if (stepnumber > i){
    steps[i].status = "complete"
  }
  }

  // ###################################################################################
  return (
    <nav aria-label="Progress">
      <ol role="list" className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {step.status === 'complete' ? (
              <a href={step.href} className="group flex items-center w-full">
                <span className="px-2 py-2 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-3 h-3 flex items-center justify-center bg-green-600 rounded-full group-hover:bg-green-800">
                    <CheckIcon className="w-3 h-3 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-100">{step.name}</span>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <a href={step.href} className="px-2 py-2 flex items-center text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0 w-7 h-7 flex items-center justify-center border-2 border-gray-100 rounded-full">
                  <span className="text-red-100">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-100">{step.name}</span>
              </a>
            ) : (
              <a href={step.href} className="group flex items-center">
                <span className="px-3 py-2 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-7 h-7 flex items-center justify-center border-2 border-gray-500 rounded-full group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}