import { Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
    <Image
      // alt="logo"
      // src="https://ewyzesvg.s3.us-west-2.amazonaws.com/Logo500.svg"
      // padding={tokens.space.medium}
    />
  );
}

