import React, { createContext, useContext, useReducer, useState, useEffect, useMemo } from 'react'
import Amplify from 'aws-amplify';
import {Routes, Route}  from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
// ________________ SURVEY PAGES AND COMPONENTS _____________________________________
import Home from "./pages/home";
import Assessment from './pages/assessment';
import Navbar from "./components/Navbar";
import Resources from './pages/resources';
import ViewAssessments from './pages/viewassessments';
import ABOUT from './pages/about';
import Assessor from './components/0_assessor';
import Governance from './components/1_governance';
import Leadership from './components/2_leadership';
import Measurement from './components/3_measurement';
import Finance from './components/4_finance';
import Support from './components/5_support';
import Standardisation from './components/6_standardisation';
import AssetInformation from './components/7_assetinformation';
import AssetLifeCycle from './components/8_assetlifecycle';
import AssetPlans from './components/9_assetplans';
import SaveSurvey from './pages/save_survey.jsx';
import NewSurvey from './pages/newsurvey';
import SurveyContext from './context/SurveyContext';
import surveyReducer from './reducers/SurveyReducers';
// _____________________________________________________
// import Footer from './components/Footer';
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import TestPage from './pages/testpage';
import NoMatch from './components/nomatch';
import { AssessmentProvider } from './context/assessmentcontext';
import ViewGovernance from './components/viewresults/viewgovernance';

// _____________________________________________________
export const UserContext = React.createContext();
export const SignOutContext = React.createContext();
export const CountContext = React.createContext()

// ####################################################################################
Amplify.configure(awsconfig);

const initialState = {  // INITIALISE the reducer state variable
  clientID: "",
  assessordata:  "",
  governance: "",
  leadership:  "",
  measurement:  "",
  finance:  "",
  support:  "",
  standardisation:  "",
  assetinfo:  "",
  assetlifecycle:  "",
  assetplan:  "",
  historicassessments: []
}

const App = ({ signOut, user }) => {
    const [state, dispatch] = useReducer(surveyReducer, initialState);


// ####################################################################################
return (
<div>
  <UserContext.Provider value={user.username}>
  {/* <UserContext.Provider value={"willemdb"}> */}
  <SignOutContext.Provider value={signOut}>
  <SurveyContext.Provider value={{state, dispatch}}>
  <AssessmentProvider>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        {/* <Route path='/newassessment' element={< TheNewAssessment />} /> */}
        <Route path='/viewassessments' element={<ViewAssessments/>} />
        <Route path='/resources' element={<Resources/>} />
        <Route path='/about' element={<ABOUT/>} />
        <Route path='/assessment' element={<Assessment/>} />
        <Route path='/newsurvey' element={< NewSurvey />} />
        <Route path='/0_assessor' element={< Assessor />} />
        <Route path='/1_governance' element={<Governance/>} />
        <Route path='/2_leadership' element={<Leadership/>} />
        <Route path='/3_measurement' element={<Measurement/>} />
        <Route path='/4_finance' element={<Finance/>} />
        <Route path='/5_support' element={<Support/>} />
        <Route path='/6_standardisation' element={<Standardisation/>} />
        <Route path='/7_assetinformation' element={<AssetInformation/>} />
        <Route path='/8_assetlifecycle' element={<AssetLifeCycle/>} />
        <Route path='/9_assetplans' element={<AssetPlans/>} />
        <Route path='/save_survey' element={<SaveSurvey/>} />
        <Route path='/viewgovernance' element={<ViewGovernance/>} />


        <Route path='/testpage' element={< TestPage />} />
        <Route path='*' element={< NoMatch  />} />
      </Routes>
  </AssessmentProvider>
  </SurveyContext.Provider>
  </SignOutContext.Provider>
  </UserContext.Provider>
</div>

    ) 
}


export default withAuthenticator(App, {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});

