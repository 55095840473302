import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import config from './aws-exports'
import Amplify from '@aws-amplify/core'
import reportWebVitals from './reportWebVitals';

Amplify.configure(config)

ReactDOM.render(
    <BrowserRouter>
    <App />
    </BrowserRouter>,
  document.getElementById('root')
);
