import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentHeader from "./assessmentheader";
import SurveyContext from "../context/SurveyContext";
// ##########################################################################

const training = [
  { id: 'level1', name: 'Level 1', description: 'Our AM training and development approach is informal and largely driven by the personal initiative of staff.' },
  { id: 'level2', name: 'Level 2', description: 'Our AM training & development requirements are defined by management based on short-term needs.  Selected staff are trained on basic AM concepts.' },
  { id: 'level3', name: 'Level 3', description: 'We provide all staff with basic AM awareness training. Staff and council are able to communicate the value of AM in their own words.' },
  { id: 'level4', name: 'Level 4', description: 'We define AM knowledge and skill requirements. A training plan is in place for all positions.' },
  { id: 'level5', name: 'Level 5', description: 'Selected staff undergo training on advanced AM concepts specific to their roles and responsibilities to support the organisational goals. Proactive, role-based training serves as a support for career development and succession planning.' },
]
const knowledgetransfer = [
  { id: 'level1', name: 'Level 1', description: 'We are aware of the need to mitigate the risk of losing information held in the minds of long-term staff.' },
  { id: 'level2', name: 'Level 2', description: 'We mitigate the risk of losing information held in the minds of long-term staff, through improved record keeping.' },
  { id: 'level3', name: 'Level 3', description: 'A culture of knowledge sharing is emerging internally, supported by official initiatives. We collect and maintain AM knowledge resources.' },
  { id: 'level4', name: 'Level 4', description: 'A culture of knowledge sharing exists and is supported by  a mix of formal & informal initiatives. We disseminate AM knowledge resources within the organisation.' },
  { id: 'level5', name: 'Level 5', description: 'We capture AM knowledge and staff leverage internal and industry knowledge and leading practices.' },
]
const changemanagement = [
  { id: 'level1', name: 'Level 1', description: 'We are investigating AM-related change management & communication needs.' },
  { id: 'level2', name: 'Level 2', description: 'We have developed a change management & communication programme.' },
  { id: 'level3', name: 'Level 3', description: 'We communicate the benefits of AM internally to staff and council.' },
  { id: 'level4', name: 'Level 4', description: 'Regular interaction with staff take place.' },
  { id: 'level5', name: 'Level 5', description: 'Various stakeholders are given the opportunity to influence communication and change programme.' },
]
// ##########################################################################
export default function Support(props) {
  const [radio1, setRadio1] = useState();
  const [radio2, setRadio2] = useState();
  const [radio3, setRadio3] = useState();

  let navigate = useNavigate();
  const surveyContext = useContext(SurveyContext);  // use the surveyDataContext variable here

function handleNext() {
    if((radio1 === undefined) || (radio2 === undefined) || (radio3 === undefined)){
    alert("PLEASE SELECT AN OPTION")
  }

  else { 
    const supportdata = {radio1, radio2, radio3}
    surveyContext.dispatch({ type: 'ADD_SUPPORT', supportdata});
    navigate(`/6_standardisation`)
  } 
}

// function handleBack() {
//   setRadio1('')
//   setRadio2('')
//   setRadio3('')
//   navigate(`/4_finance`)
// }

return (
  
<div className="bg-gray-800 p-10 min-h-screen flex items-center justify-center">   {/* sets the background here */}
<div className="grid grid-cols-1 gap-2">  {/* The grid starts here */}

{/* __________________________________________________________________________________ */}

{/* <AssessmentHeader/> */}

<div>
<AssessmentHeader statusdata={['5']} />
</div>

<div className="pb-5 border-b border-gray-200">
      <h1 className="text-lg leading-6 font-medium text-center text-gray-100">SUPPORT</h1>
      <h5 className="text-lg leading-6 font-medium text-gray-300">
      By developing this competency, your organisation is supporting staff in asset management change, training and development and sharing information with respect to the benefits of asset management.	
    </h5>
    </div>

<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME A: TRAINING AND DEVELOPMENT
  </h1>

</div>

<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {training.map((train) => (
          <div key={train.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={train.id}
                aria-describedby={`${train.id}-description`}
                name="train"
                type="radio"
                value={train.id}
                onChange={(e) => setRadio1(e.target.value)}
                defaultChecked={train.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={train.id} className="font-medium text-gray-700">
                {train.name}
              </label>
              <p id={`${train.id}-description`} className="text-gray-500">
                {train.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>
{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME B: KNOWLEDGE TRANSFER
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {knowledgetransfer.map((knowledge) => (
          <div key={knowledge.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={knowledge.id}
                aria-describedby={`${knowledge.id}-description`}
                name="knowledge"
                type="radio"
                value={knowledge.id}
                onChange={(e) => setRadio2(e.target.value)}
                defaultChecked={knowledge.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={knowledge.id} className="font-medium text-gray-700">
                {knowledge.name}
              </label>
              <p id={`${knowledge.id}-description`} className="text-gray-500">
                {knowledge.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}
<div className="bg-gray-600 p-3 rounded text-lg text-white text-center">
  <h1>
    OUTCOME C: CHANGE MANAGEMENT AND COMMUNICATION
  </h1>
</div>
<div className="bg-gray-100 p-3 rounded text-lg text-white"> 
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="space-y-5">
        {changemanagement.map((change) => (
          <div key={change.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={change.id}
                aria-describedby={`${change.id}-description`}
                name="legal"
                type="radio"
                value={change.id}
                onChange={(e) => setRadio3(e.target.value)}
                defaultChecked={change.id === 'small'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={change.id} className="font-medium text-gray-700">
                {change.name}
              </label>
              <p id={`${change.id}-description`} className="text-gray-500">
                {change.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
</div>

{/* __________________________________________________________________________________ */}

<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 flex sm:mt-0 sm:ml-4">
      {/* <button
          type="button"
          onClick={handleBack}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          BACK
        </button> */}
      <button
          type="button"
          onClick={handleNext}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          NEXT
        </button>

      </div>
    </div>

{/* __________________________________________________________________________________ */}



    </div>
    </div>
  )


}