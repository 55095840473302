import React from 'react';
import { AnnotationIcon, GlobeAltIcon, DocumentIcon, ScaleIcon } from '@heroicons/react/outline'

const documents = [
  {
    name: 'Asset Management Roadmap',
    href: 'https://www.dropbox.com/s/nkaxcrcqah55qre/Asset_Management.pdf?dl=0',
    description:
      'The objective of this roadmap is to assist organizations to implement asset management practices. Thorughout this document the term organization refers to local governments, municipalities and any other organization that manages public infrastructure.Implementing asset management practices may seem like a very daunting task to an organization just starting on the asset management journey. However when you begin this journey you will soon realize that many of the ideas, concepts, and or data collections tasks may already be part of your daily operations and culture.',
    icon: DocumentIcon,
  },
  {
    name: 'Guide on How to Use the Asset Management Assessment Tool',
    href: 'https://www.dropbox.com/s/6dic9ghv0lgv0oq/GUIDE.pdf?dl=0',
    description:
      'This guide has been designed for Municipalities of all sizes who are just at the beginning stages of developing asset management practices and who want to utilise and better understand The University of Pretoria Asset Management Readiness Scale. This guide discusses the competencies in the order in which they appear in the Asset Management Readiness Scale. However, the order of these competencies does not necessarily reflect the order in which asset management is done. If, after reviewing the Readiness Scale, you identify one or a few competencies that are more important than others, you can start there.',
    icon: DocumentIcon,
  },

]

export default function Resources() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            ASSET MANAGEMENT RESOURCES
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Here you will find resources that the team has been working on. The documents are a work in progress and we look forward to your feedback to help us improve.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {documents.map((document) => (
              <div>
              <a target="_blank" key={document.name} href={document.href} className="ml-16 text-lg leading-6 font-medium text-red-700">{document.name} </a>
                <dd className="mt-2 ml-16 text-base text-gray-500">{document.description}</dd>
              </div>
            ))}
          </dl>
          </div>
        <a/>
      </div>
    </div>
  )
}