import React from 'react';

export default function ABOUT() {

  //####################################################################
  return (
    <div className='h-screen flex justify-center items-center bg-blue-300'>
      <h1 className='text-9xl uppercase font-black'>ABOUT</h1>
    </div>
  );
  }

  