import React from 'react'
import { useState, useEffect } from "react";

const ViewGovernance = (props) => {
    const [currentscore, setCurrentScore] = useState(0);
    const [mapdata, setMapData] = useState([
        {
          name: 'GOVERNANCE - Policy & Objectives', 
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'GOVERNANCE - Strategy & Roadmap',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'GOVERNANCE - Legal Obligations',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'LEADERSHIP - Cross-functional teams',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'LEADERSHIP - Accountability',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'LEADERSHIP - Commitment',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'MEASUREMENT & MONITORING - Progress Monitoring',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'MEASUREMENT & MONITORING - Performance Monitoring',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'FINANCE - Finance Information',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'FINANCE - Budgeting',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'FINANCE - Financial Planning',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'SUPPORT - Training & Development',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'SUPPORT - Knowledge Transfer',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'SUPPORT - Change Management & Communication',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'STANDARDISATION - Asset Business Processes Management',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'ASSET INFORMATION - Asset Inventory',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'ASSET INFORMATION - Asset Data',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'ASSET INFORMATION - Asset Condition',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'ASSET LIFE CYCLE - Investment Planning (CAPEX)',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'ASSET LIFE CYCLE - Asset Renewal',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'ASSET LIFE CYCLE - Asset Maintenance (OPEX)',
          description: 'SELECT ASSESSMENT',
        },
        {
          name: 'ASSET MANAGEMENT PLANS',
          description: 'SELECT ASSESSMENT',
        },


      ])

      useEffect(() => { 
            if (props.data !== undefined) 
              setMapData([
                {
                  name: 'GOVERNANCE - Policy & Objectives', 
                  description: props.data[0].governance.option1selection,
                },
                {
                  name: 'GOVERNANCE - Strategy & Roadmap',
                  description: props.data[0].governance.option2selection,
                },
                {
                  name: 'GOVERNANCE - Legal Obligations',
                  description: props.data[0].governance.option3selection,
                },
                {
                  name: 'LEADERSHIP - Cross-functional teams',
                  description: props.data[0].leadership.option1selection,
                },
                {
                  name: 'LEADERSHIP - Accountability',
                  description: props.data[0].leadership.option2selection,
                },
                {
                  name: 'LEADERSHIP - Commitment',
                  description: props.data[0].leadership.option3selection,
                },
                {
                  name: 'MEASUREMENT & MONITORING - Progress Monitoring',
                  description: props.data[0].measurement.option1selection,
                },
                {
                  name: 'MEASUREMENT & MONITORING - Performance Monitoring',
                  description: props.data[0].measurement.option2selection,
                },
                {
                  name: 'FINANCE - Finance Information',
                  description: props.data[0].finance.option1selection,
                },
                {
                  name: 'FINANCE - Budgeting',
                  description: props.data[0].finance.option2selection,
                },
                {
                  name: 'FINANCE - Financial Planning',
                  description: props.data[0].finance.option3selection,
                },
                {
                  name: 'SUPPORT - Training & Development',
                  description: props.data[0].support.option1selection,
                },
                {
                  name: 'SUPPORT - Knowledge Transfer',
                  description: props.data[0].support.option2selection,
                },
                {
                  name: 'SUPPORT - Change Management & Communication',
                  description: props.data[0].support.option3selection,
                },
                {
                  name: 'STANDARDISATION - Asset Business Processes Management',
                  description: props.data[0].standardisation.option1selection,
                },
                {
                  name: 'ASSET INFORMATION - Asset Inventory',
                  description: props.data[0].assetinfo.option1selection,
                },
                {
                  name: 'ASSET INFORMATION - Asset Data',
                  description: props.data[0].assetinfo.option2selection,
                },
                {
                  name: 'ASSET INFORMATION - Asset Condition',
                  description: props.data[0].assetinfo.option3selection,
                },
                {
                  name: 'ASSET LIFE CYCLE - Investment Planning (CAPEX)',
                  description: props.data[0].assetlifecycle.option1selection,
                },
                {
                  name: 'ASSET LIFE CYCLE - Asset Renewal',
                  description: props.data[0].assetlifecycle.option2selection,
                },
                {
                  name: 'ASSET LIFE CYCLE - Asset Maintenance (OPEX)',
                  description: props.data[0].assetlifecycle.option2selection,
                },
                {
                  name: 'ASSET MANAGEMENT PLANS',
                  description: props.data[0].assetplan.option1selection,
                }
              ])          


      }, [props.data]);


  return (
    <div>
    <div className="bg-green-800">
 
        <ul role="list" className="divide-y divide-gray-200">
      {mapdata.map((item) => (
        <li key={item.name} className="flex py-4">
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-100">{item.name}</p>
            <p className="text-sm text-gray-300">{item.description}</p>
          </div>
        </li>
      ))}
    </ul>

    </div>     
  </div>
  )
}

export default ViewGovernance
